<template>
  <action-edit
    :action-url="`newsletter/templates/${item.id}`"
    :item="item"
    button-label="Editeaza Sablonul-ul"
  >

    <template v-slot:fields="{form, errors}">

      <div class="mb-4">
        <v-text-field v-model="form.name" dense hide-details label="Denumire" outlined/>
        <error-messages :errors="errors.name"/>
      </div>

    </template>
  </action-edit>
</template>

<script>
import ErrorMessages from '@/components/general-form/ErrorMessages'
import ActionEdit from '@/components/layout/ActionButtons/ActionEdit'

export default {
  components: {
    ActionEdit,
    ErrorMessages
  },
  props: {
    item: {
      required: true
    }
  }
}

</script>
